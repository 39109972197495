<template>
   <div class="modal__content-block">
        <p class="modal__content-subtitle">Дополнительно</p>

        <div class="modal__activity-additional">
             <ActivityAdditionalPoints></ActivityAdditionalPoints>
             <ActivityAdditionalPointsCommentary></ActivityAdditionalPointsCommentary>
        </div>

   </div>
</template>

<script>
import ActivityAdditionalPointsCommentary
  from "@/components/ActivityRegistry/modalActivity/ActivityOptional/ActivityAdditionalPointsCommentary";
import ActivityAdditionalPoints
  from "@/components/ActivityRegistry/modalActivity/ActivityOptional/ActivityAdditionalPoints";

export default {
  name: "ActivityOptional",
  components: {
    ActivityAdditionalPoints,
    ActivityAdditionalPointsCommentary
  }
}
</script>

<style scoped>

</style>