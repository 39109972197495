import { render, staticRenderFns } from "./ActivityAuthor.vue?vue&type=template&id=0bb2bae5&scoped=true&"
import script from "./ActivityAuthor.vue?vue&type=script&lang=js&"
export * from "./ActivityAuthor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb2bae5",
  null
  
)

export default component.exports