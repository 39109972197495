<template>
      <div class="activity__registry-table">
        <div class="article-registry__filters">
              <activity-registry-filter title="Фильтры">
              </activity-registry-filter>
          </div>
          <div class="article-registry__page-control">
                <button v-on:click="loadPreviousPage"
                         v-if="isPreviousActivityPageVisible"
                         class="article-registry__page-control-previous">
                  Предыдущая страница
                </button>
                 <button v-on:click="loadNextPage"
                         v-if="isNextActivityPageVisible"
                         class="article-registry__page-control-next">
                   Следующая страница
                 </button>
          </div>
         <div class="activity__registry-table-content"> 
         <div class="activity__registry-table-row activity__registry__table-row--head">
           
                  <div class="activity__registry-table-sell activity__registry-table-sell--id">№</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--tb">ТБ</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--author">Автор</div>

                  <div class="activity__registry-table-sell activity__registry-table-sell--activity-name">Блок активности</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--activity-name">Вид активности</div>
                  <!-- <div class="activity__registry-table-sell activity__registry-table-sell--activity-type">Описание активности</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--activity-name">Эффект от активности</div> -->
                  <div class="activity__registry-table-sell activity__registry-table-sell--author">Дата</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--author">Статус активности</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--additional">Балл</div>

                  <div class="activity__registry-table-sell activity__registry-table-sell--additional">Доп. балл ЦА</div>
                  <div class="activity__registry-table-sell activity__registry-table-sell--author">Комментарий ЦА</div>
         </div>
         <ActivityRow
           v-for="activity in activityData"
           v-bind:key = "activity.id"
           v-bind:ActivityRow ="activity"
           @click.native="showActivityModal(activity.id)"
       ></ActivityRow>
         </div>
      </div>
</template>

<script>
import ActivityRow from "@/components/ActivityRegistry/ActivityRow";
import activityRegistryFilter from "@/components/ActivityRegistry/activityRegistryFilter/activityRegistryFilter";
import { mapState } from 'vuex';
import QuarterGenerator from "../../utils/QuarterGenerator";

export default {
  name: "ActivityTable",
  data() {
    return {
       filters: {
          tb: '',
          quarter: ''
      },
      quarterOptionsList: QuarterGenerator.generateQuarters('2021-03-01')
    }
  },

  components: {
    ActivityRow,
    activityRegistryFilter
  },
  mounted() {
    this.$store.dispatch('ActivityTableStore/getActivity', this.$route.query);
  },
  computed: mapState ({
    activityDataLoaded: state => state.ActivityTableStore.activityDataLoaded,
    activityData: state => state.ActivityTableStore.activity,
    isNextActivityPageVisible: state => state.ActivityTableStore.activityNextButton,
    isPreviousActivityPageVisible: state => state.ActivityTableStore.activityPreviousButton
  }),
  methods: {
    showActivityModal: function (id) {
        this.$store.dispatch('modalActivity/getActivityData', id)
    },
    loadNextPage() {
        let newPageNumber = parseInt(this.$route.meta.query.page) + 1
        this.filterContentFromAPI({page: newPageNumber})
    },
    loadPreviousPage() {
        let newPageNumber = parseInt(this.$route.meta.query.page)-1
        this.filterContentFromAPI({page: newPageNumber})
    },
    filterContentFromAPI(routeObject) {
      this.$route.meta.query={...this.$route.meta.query, ...routeObject}
      this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
    dropFilter(){
        this.filters.tb = '';
        this.filters.quarter= '';
        this.$route.meta.query={page:1}
        this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    }
  }
}
</script>

<style scoped>

</style>
