<template>
    <div class="modal__content-block">
                 <p class="modal__content-subtitle">Эффект от активности</p>
            <div class="modal__activity-name">
                 <textarea v-model="modalActivityData.activity_full_descriprion"
                        :disabled="modalActivityInvalid"
                 ></textarea>
                 <!-- <div v-if="error">
                   <p class="input__message--invalid">Не вписано описание активности</p>
                 </div> -->
            </div>
    </div>

</template>

<script>
import {mapState} from "vuex";
export default {
     name: "ActivityName",
     props: ['error'],
     computed: mapState ({
       modalActivityData: state => state.modalActivity.activity,
       modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
     })
}
</script>

<style scoped>
</style>