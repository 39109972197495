import ActivityService from "@/services/activity.service"


const InitialState = {
        activity: [],
        activityDataLoaded: false,
        activityNextButton: false,
        activityPreviousButton: false
    }

export const ActivityTableStore = {
    namespaced: true,
    state: () => (InitialState),
    mutations: {
        getActivity (state, activityData) {
          state.activity = activityData.results
          state.activityNextButton = activityData.next != null;
          state.activityPreviousButton = activityData.previous != null;
        },
        loadingActivityStatus (state, newLoadingStatus) {
            state.activityDataLoaded = newLoadingStatus
        }
    },
    actions: {
        getActivity ({ commit }, query) {
            commit('loadingActivityStatus', true)

            return ActivityService.getAllActivity(query)
                .then(result => {
                    commit('getActivity', result.data)
                    commit('loadingActivityStatus', false)
                })
                .catch(error => console.log(error))
        }
    },
    getters: {
        getActivityData(state) {
            return state.activity
        },
        getActivityDataLoaded(state) {
            return state.activityDataLoaded
        }
    }
}
