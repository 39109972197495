<template>
    <div class="modal__activity-additional-points">
        <label for="additionalPoints">Доп.балл</label>
        <input v-model="modalActivityData.additional_points"
               :disabled="!modalActivityData.is_staff"
               id="additionalPoints"
               type="number"
          >
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ActivityAdditionalPoints",
   computed: mapState ({
     modalActivityData: state => state.modalActivity.activity,
     modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
  }),
}
</script>

<style scoped>

</style>