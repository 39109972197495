<template>
  <div class="container">
      <Header></Header>
      <div class='content'>
        <div class="container-header">
          <p>РЕЕСТР АКТИВНОСТЕЙ</p>
        </div>
          <div class="article-registry">
               <div class="article-registry__header">
                      <button v-on:click="createNewActivity" class="article-registry__add-post">
                        <font-awesome-icon icon="plus" />
                       Добавить активность
                      </button>
                      
                      <load-excel-button v-bind:load-type="2"></load-excel-button>
              </div>
              <ActivityTable></ActivityTable>
            </div>

        <Footer></Footer>
      </div>
        <modal_activity
            v-if="modalVisible"
        ></modal_activity>
        <div class="modal__background" v-if="modalVisible"></div>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import ActivityTable from "@/components/ActivityRegistry/ActivityTable";
import modal_activity from "@/components/ActivityRegistry/modal_activity";
import { ActivityTableStore } from "@/store/activity.module";
import { ActivityTypesStore } from "@/store/activity/activityTypeList"
import loadExcelButton from "@/components/Assets/Buttons/loadExcelButton";

import {mapState} from "vuex";
export default {
  name: "ActivityRegistry",
  computed: mapState({
    modalVisible: state => state.modalActivity.isActivityModalVisible
  }),
  components: {
    Header,
    Footer,
    ActivityTable,
    modal_activity,
    loadExcelButton
  },
  mounted() {
      this.$store.dispatch('ActivityTypesStore/getActivityTypesListFromAPI')
  },
  methods: {
      createNewActivity: function () {
        this.$store.dispatch('modalActivity/createNewActivity')
      }
  },
  created() {
    this.$store.registerModule("ActivityTableStore", ActivityTableStore);
    this.$store.registerModule("ActivityTypesStore", ActivityTypesStore);
    this.$route.meta.query={page:1}
  },
  beforeDestroy() {
    this.$store.unregisterModule("ActivityTableStore", ActivityTableStore);
    this.$store.unregisterModule("ActivityTypesStore", ActivityTypesStore);
  }

}
</script>

<style scoped>
</style>