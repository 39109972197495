<template>
        <div class="modal__content-block">
        <p class="modal__content-subtitle">Дата активности</p>

        <div class="modal__activity-name">
          <div class="modal__activity-wrapper">
            <p v-html="computeDate(modalActivityData.activity_date)"></p>
            <input v-model="modalActivityData.activity_date"
                   :disabled="modalActivityInvalid"
                   class="modal__activity-date-input"
                   type="date"
            >
          </div>

               <div v-if="error">
                   <p class="input__message--invalid">Не выбрана дата</p>
                </div>
        </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "ActivityDate",
  props: ['error'],
  computed: mapState ({
       modalActivityData: state => state.modalActivity.activity,
       modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
    }),
  methods: {
    computeDate(value){
      return moment(value).format('DD-MM-YYYY')
    }
  }
}
</script>

<style scoped>

</style>