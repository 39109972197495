import BaseRegistryEntityType from "../registry/BaseRegistryEntityType"
import ActivityService from "@/services/activity.service"

const InitialState = {
        ...BaseRegistryEntityType.state
    }

export const ActivityTypesStore = {
    namespaced: true,
    state: () => (InitialState),
    mutations: {
       ...BaseRegistryEntityType.mutations
    },
    actions: {
        getActivityTypesListFromAPI({ commit }) {
            return ActivityService.getTypes()
                .then(result => {
                    commit('setEntityTypesList', result.data)
                })
                .catch(error => console.log(error))
        }
    }
}