<template>
    <div class="filter__container">
        <div class="filter__title">
        <p>{{title}}:</p>
            <div v-if="activityDataLoaded" class="filter__container-update">
                <p class="filter__text-update">Идёт обновление</p>
                <spinner size="15"></spinner>
            </div>
        </div>
        <div class="filter__wrapper__activity-registry">
            <select-type-filter :select-filter-data="articleTBList" ref="tb"></select-type-filter>
            <select-type-filter :select-filter-data="articleQuarterList" ref="quarter"></select-type-filter>
        </div>
        <div class="filter__container-buttons">
            <button class="filter__button--clear" @click="dropFilter()">Сбросить все фильтры</button>
        </div>
    </div>
</template>
<script>
import selectTypeFilter from "@/components/Assets/Filters/selectTypeFilter.vue";
import QuarterGenerator from "@/utils/QuarterGenerator";
import { mapState } from 'vuex';
import Spinner from 'vue-simple-spinner'
export default {
    props: ['title'],
    data() {
      return {
          articleTBList:{
              filterKey:'tb',
              filterName:'ТБ',
              filterValuesList:[{ id: 0, value:"ББ"},
                                { id: 1, value: "УБ"},
                                { id: 2, value:"ВВБ"},
                                { id: 3, value:"СЗБ"},
                                { id: 4, value:"СРБ"},
                                { id: 5, value:"ПБ"},
                                { id: 6, value:"СиББ"},
                                { id: 7, value:"ЦЧБ"},
                                { id: 8, value:"ЮЗБ"},
                                { id: 9, value:"ДВБ"},
                                { id: 10, value:"МБ"}]
          },
          articleQuarterList:{
              filterKey:'quarter',
              filterName:'Квартал',
              filterValuesList:[],
          },
        }
    },
    components: {
       selectTypeFilter,
       Spinner,
       
    },
    computed: mapState ({
        activityDataLoaded: state => state.ActivityTableStore.activityDataLoaded,
        }),
    mounted(){
        QuarterGenerator.generateQuarters('2021-03-01').forEach(item=>this.articleQuarterList.filterValuesList.push({id:item.id,value:item.name}))
        
    },
    methods:{
        resetFilters(){
            this.$refs.tb.filteredValuesList=null;
            this.$refs.quarter.filteredValuesList=null;
        },
        dropFilter(){
            this.resetFilters();
            this.$route.meta.query={page: 1}
            this.$store.dispatch(this.$route.meta.type, this.$route.meta.query);
    },
  }
}
</script>