<template>
     <div class="modal__content-block">
         <p class="modal__content-subtitle">Активность</p>
         <div class="modal__activity-wrapper">
            <label>Блок активности:</label>
            <select v-show="modalActivityData.activity_type_id.activity_name==null" class=""
                             name=""
                             v-model="modalActivityData.activity_block"
                             :disabled="modalActivityInvalid"
                     >
                         <option>Сопровождение NTA</option>
                         <option>Организация ЦК</option>
                         <option>Проекты</option>
                         <option>Разовые</option>
                     </select>
                     <p v-show="modalActivityData.activity_type_id.activity_name">{{modalActivityData.activity_block}}</p>
            <div v-if="error">
               <p class="input__message--invalid">Не выбран блок активности</p>
            </div>
           
         </div>
         <div class="modal__activity-wrapper" v-if="modalActivityData.activity_block!=null
                                                      &&this.type_activity!=modalActivityData.activity_block">
            <label>Список активностей:</label>
            <select v-show="modalActivityData.activity_type_id.activity_name==null" class="modal__activity-type"
                    name=""
                    v-on="this.formatText()"
                    v-model="modalActivityData.activity_type_id"
                    :disabled="modalActivityInvalid"
            >
                
                <option v-for="item in activityTypesList"
                        v-show="item.activity_block==modalActivityData.activity_block"
                        :key="item.id"
                        :value="item.id"
                        :disabled="!item.is_active"
                >
                  {{item.activity_name}}
                </option>
            </select>
            <p v-show="modalActivityData.activity_type_id.activity_name">{{modalActivityData.activity_type_id.activity_name}}</p>
         </div>
         
         <div class="modal__activity-wrapper__type_activity" v-if="this.type_activity==modalActivityData.activity_block">
            <div >
               <label>Название проекта: </label>
            <input type="text" v-model="modalActivityData.activity_project_name" ></div>
            <div  > 
               <div >
                  <label>Наименование вехи:</label>
                  <textarea v-model="modalActivityData.activity_project_veha" ></textarea></div>
               </div>
            <div v-if="error">
               <p class="input__message--invalid">Проект</p>
            </div>
         </div>
         <div style="padding-top:10px;" class="modal__activity-name" >
               <label style="padding-bottom:10px;">Описание активности:</label>
               <textarea v-model="modalActivityData.activity_name" :disabled="modalActivityInvalid"></textarea>
            </div>
            <!-- <div v-if="this.$v.modalActivityData.activity_name.$error">
                   <p class="input__message--invalid">Не вписано описание активности</p>
                 </div> -->
         
     </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "ActivityType",
  props: ['error'],
  data() {
    return {
       type_activity: 'Проекты',
       prj_info:[]
    }},
     
  computed: mapState ({
     modalActivityData: state => state.modalActivity.activity,
     modalActivityInvalid: state => state.modalActivity.modalActivityInvalid,
     activityTypesList: state => state.ActivityTypesStore.entityTypesList,
   }),
   
   mounted(){
      if(this.modalActivityData.activity_effect!=null){
      this.prj_info=this.modalActivityData.activity_effect.split('.')
      }
   },
   methods:{
      formatText(){
         // if(this.modalActivityData.name_prj!=null||this.modalActivityData.veha_prj!=null){
         //    this.modalActivityData.name_prj=null
         //    this.modalActivityData.veha_prj=null
         // }
      }
   }
  
}
</script>

<style scoped>
</style>