<template>
  <div class="modal__content-block">
        <p class="modal__content-subtitle">Статус активности</p>

        <div class="modal__activity-status">
            <p v-if="!modalActivityData.is_staff"
               v-html="modalActivityData.activity_status"></p>

          <select v-model="modalActivityData.activity_status"
                  v-if="modalActivityData"
                  :disabled="modalActivityInvalid"
                  >
                  <option>Новая</option>
                  <option>Одобрено</option>
                  <option>Отклонено</option>
          </select>
        </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
    name: "ActivityStatus",
    computed: mapState ({
        modalActivityData: state => state.modalActivity.activity,
        modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
    }),
}
</script>

<style scoped>

</style>