<template>

  <div class="modal modal__activity">
     <div class="modal__container">
        <div v-if="modalActivityLoaded">
          <spinner></spinner>
        </div>

        <div v-else>
          <h4 v-if="modalActivityInvalid" class="modal__activity-title text--red">Редактирование данной активности запрещено</h4>
          <h4 v-if="!modalActivityData.id" class="modal__activity-title">Добавление новой активности</h4>
           <p v-if="modalActivityData.id != 'null'" class="modal__activity-subtitle"> ID: {{modalActivityData.id}}</p>
          <form v-on:submit.prevent>
             <activity-author v-bind:error="{
                                             'tb': this.$v.modalActivityData.tb.$error}"></activity-author>
             <activity-type></activity-type>
             <activity-points v-if="this.info.is_base_bank"></activity-points>
             <activity-name ></activity-name>
             <ActivityDate></ActivityDate>
             <activity-status></activity-status>
            <activity-optional v-if="this.info.is_base_bank"></activity-optional>



            <div class="modal__activity-wrapper modal__activity-wrapper--justify">
                 <button v-on:click="submitActivityForm"
                         :disabled="modalActivityInvalid"
                         class="modal__activity-save">Сохранить</button>

                 <button v-on:click="deleteActivity"
                         class="modal__activity-delete"
                         name="delete"
                         value="Удалить"
                         v-if="this.info.is_base_bank">
                         Удалить
                 </button>
                 <button v-on:click="closeModal"
                         class="modal__activity-cancel"
                         name="submit"
                         value="Отмена">
                   Закрыть
                 </button>

            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>

import Spinner from 'vue-simple-spinner'
import ActivityAuthor from "@/components/ActivityRegistry/modalActivity/ActivityAuthor";
import ActivityStatus from "@/components/ActivityRegistry/modalActivity/ActivityStatus";
import ActivityDate from "@/components/ActivityRegistry/modalActivity/ActivityDate";
import ActivityName from "@/components/ActivityRegistry/modalActivity/ActivityName";
import ActivityType from "@/components/ActivityRegistry/modalActivity/ActivityType";
import ActivityOptional from "@/components/ActivityRegistry/modalActivity/ActivityOptional";
import { mapState } from 'vuex';
import {required, minLength} from "vuelidate/lib/validators";
import ActivityPoints from "./modalActivity/ActivityPoints";
import axios from 'axios'
import authHeader from "@/services/auth-header";

export default {
  name: "modal_activity",
  props: ['activityID'],
    data() {
    return {
      info:[]
    }},
  mounted(){
    axios
        .get('https://backend.newtechaudit.ru/api/accounts/users/me/' , {
            headers: authHeader()
        })
        .then(response => {this.info = response.data
    })
  },
  computed: mapState ({
    modalActivityLoaded: state => state.modalActivity.modalActivityLoaded,
    modalActivityData: state => state.modalActivity.activity,
    modalActivityInvalid: state => state.modalActivity.modalActivityInvalid,
  }),
  components: {
    ActivityPoints,
    Spinner,
    ActivityStatus,
    ActivityDate,
    ActivityAuthor,
    ActivityName,
    ActivityType,
    ActivityOptional,
  },
  validations: {
     modalActivityData: {
       activity_name: {
         required,
         minLength: minLength(10)
       },
       activity_type_id: {
         required
       },
       points: {
         required
       },
       author: {
         required,
         minLength: 10
       },
       activity_date: {
         required
       },
       tb: {
         required
       }
     }
  },
  destroyed(){
    this.closeModal();
  },
  methods: {
    closeModal() {
      this.$store.dispatch('modalActivity/closeActivityModal', this.$route.meta.query)
    },
    submitActivityForm(){
      this.$store.dispatch('modalActivity/saveActivityData', this.modalActivityData).then(() =>{
           this.closeModal()
         })

      // this.$v.modalActivityData.$touch()
      //  if (!this.$v.modalActivityData.$invalid) {
      //    this.$store.dispatch('modalActivity/saveActivityData', this.modalActivityData).then(() =>{
      //      this.closeModal()
      //    })
      // }
    },
    deleteActivity(){
        this.$store.dispatch('modalActivity/deleteActivityData', this.modalActivityData.id)
            .then( () => {
              this.closeModal()
            })
    }
  }
}

</script>

<style scoped>

</style>
