<template>
          <div class="activity__registry-table-row activity__registry__table-hovered">
            <div  class="activity__registry-table-sell activity__registry-table-sell--id" v-html="ActivityRow.id"></div>
            <div  class="activity__registry-table-sell activity__registry-table-sell--tb" v-html="ActivityRow.tb"></div>
            <div class="activity__registry-table-sell activity__registry-table-sell--author" v-html="ActivityRow.author=='Иванов Иван'?'':ActivityRow.author"></div>

            <div class="activity__registry-table-sell activity__registry-table-sell--activity-name" v-html="ActivityRow.activity_block">Активность</div>
            <!-- <div  class="activity__registry-table-sell activity__registry-table-sell--activity-name" v-html="ActivityRow.activity_block=='Проекты'?'<b>Проект:</b>'+this.list_project[0]+'<b>Веха проекта:</b>'+this.list_project[1]:ActivityRow.activity_type_id.activity_name"></div> -->
            <div  class="activity__registry-table-sell activity__registry-table-sell--activity-name" 
                  v-html="ActivityRow.activity_block=='Проекты'?'<b>Проект:</b> '+ 
                  ActivityRow.activity_project_name +'<b>Веха проекта:</b>'+ ActivityRow.activity_project_veha:
                  ActivityRow.activity_type_id.activity_name"></div>
            <div class="activity__registry-table-sell activity__registry-table-sell--author" v-html="articleDateAdded">Квартал</div> 
            <div class="activity__registry-table-sell activity__registry-table-sell--author" v-html="ActivityRow.activity_status">Статус</div>
            <div class="activity__registry-table-sell activity__registry-table-sell--additional" v-html="ActivityRow.points">Балл</div>
            <div class="activity__registry-table-sell activity__registry-table-sell--additional" v-html="ActivityRow.additional_points">Доп. балл ЦА</div>
            <div class="activity__registry-table-sell activity__registry-table-sell--author" v-html="ActivityRow.commentary_ca">Комментарий ЦА</div>
        </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "ActivityRow",
  props: ['ActivityRow'],
  data() {
    return {
       list_project: [],
       articleDateAdded:''
    }},
    mounted(){
         this.list_project=this.ActivityRow.activity_effect.split('.')
         this.articleDateAdded = moment(new Date(this.ActivityRow.activity_date)).format("DD-MM-YYYY")
    },
   
  
}

</script>

<style scoped>

</style>
