import { render, staticRenderFns } from "./ActivityDate.vue?vue&type=template&id=15f59bc4&scoped=true&"
import script from "./ActivityDate.vue?vue&type=script&lang=js&"
export * from "./ActivityDate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f59bc4",
  null
  
)

export default component.exports