<template>
             <div class="modal__content-block">
                 <p class="modal__content-subtitle">Баллы за активность</p>

                 <div class="modal__activity-wrapper">
                    <label>Количество баллов</label>
                    <select class="modal__activity-type"
                            name=""
                            v-model="modalActivityData.points"
                            :disabled="modalActivityInvalid"
                    >x
                      <option value="1" selected>1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    <div v-if="error">
                       <p class="input__message--invalid">Не проставлены баллы</p>
                    </div>
                 </div>
             </div>
</template>


<script>
import {mapState} from "vuex";

export default {
  name: "ActivityPoints",
  props: ['error'],

  computed: mapState ({
     modalActivityData: state => state.modalActivity.activity,
     modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
   })
}
</script>

<style scoped>

</style>