<template>
     <div class="modal__activity-additional-commentary">
        <label for="commentary_ca">Комментарий ЦА</label>
        <input v-model="modalActivityData.commentary_ca"
               :disabled="!modalActivityData.is_staff"
               id="commentary_ca"
               type="text"
          >
    </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ActivityAdditionalPointsCommentary",
   computed: mapState ({
     modalActivityData: state => state.modalActivity.activity,
     modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
  }),
}
</script>

<style scoped>

</style>