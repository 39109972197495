<template>
       <div class="modal__activity__tb">
                     <label for="">ТБ:</label>
                     <select class=""
                             name=""
                             v-model="modalActivityData.tb"
                             :disabled="modalActivityInvalid"
                     >
                         <option>ББ</option>
                         <option>УБ</option>
                         <option>ВВБ</option>
                         <option>СЗБ</option>
                         <option>СРБ</option>
                         <option>ПБ</option>
                         <option>СиББ</option>
                         <option>ЦЧБ</option>
                         <option>ЮЗБ</option>
                         <option>ДВБ</option>
                         <option>МБ</option>
                     </select>
                     <div v-if="error">
                       <p class="input__message--invalid">Не выбран ТБ</p>
                    </div>
                   </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ActivityTB",
  props: ['error'],
    computed: mapState ({
       modalActivityData: state => state.modalActivity.activity,
       modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
   }),
}
</script>

<style scoped>

</style>