<template>
  <div class="modal__content-block">
       <p class="modal__content-subtitle">Личные данные</p>
        <div class="modal__activity-wrapper"> 
              <div class="modal__activity-author" v-show="modalActivityData.author!='Иванов Иван'">
                <label>Имя автора:</label>
                <input v-model="modalActivityData.author"
                       :disabled="modalActivityInvalid"
                >
                 <div v-if="error.author">
                   <p class="input__message--invalid">Вы не добавили автора</p>
                </div>
              </div>
              <activity-t-b v-bind:error="error.tb"></activity-t-b>
        </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ActivityTB from "@/components/ActivityRegistry/modalActivity/ActivityTB";

export default {
  name: "ActivityAuthor",
  props: ['error'],
  components: {
    ActivityTB
  },
   computed: mapState ({
       modalActivityData: state => state.modalActivity.activity,
       modalActivityInvalid: state => state.modalActivity.modalActivityInvalid
   }),
}
</script>

<style scoped>

</style>